import React, { } from "react";

import Tags from "../../articles/Tags";
import Breadcrumb from "../../articles/Breadcrumb";

import profileSSImg from "../../../assets/img/sarunas-profile.jpeg";
import postImg from "../../../assets/img/posts/best-800-min.jpg";
import thumbImg from "../../../assets/img/posts/best-300-min.jpg";

export const Article = {
  alias: 'best',
  tag: 'culture',
  colour: 'danger',
  blogImg: postImg,
  thumbImg: thumbImg,
  profileImg: profileSSImg,
  author: 'Sarunas Savicianskas',
  posted: 'Oct 16th, 2017',
  title: 'Best products are best people',
  intro: 'A great topic to mention when identifying what makes a great product is the people who build your product.',
  post: <Post />
};

export default function Post(props) {  
  return (
    <React.Fragment>
      <article className="article">
        <p>
          A great topic to mention when identifying what makes a great product is the people who build your product. The <mark data-aos="highlight-text" data-aos-delay="250">right people will make the product and the wrong people will break the product</mark>.
        </p>
        <blockquote className="blockquote">
          <a href="https://www.wsj.com/articles/harrys-co-founder-on-how-the-shaving-startup-chose-its-first-hires-1413751954" rel="noopener noreferrer" target="_blank" title="Company’s culture is set by its first hires">A company’s culture is set by its first 10 to 15 hires</a> — Jeff Raider, cofounder of Harry’s
        </blockquote>
        <p>
          I’ve seen products with momentum get destroyed because of bad hires and I’ve seen some great people who worked great despite the fact that someone else was slowing down the momentum.
        </p>
        <p>
          There are a couple of possible ways to do hiring and those mostly focus on key requirements for the people:
        </p>
        <ul>
          <li>
            Technical fit — the domain knowledge of how to build stuff.
          </li>
          <li>
            Cultural fit — the way the people think about the industry they work in, product building and about work — life balance.
          </li>
          <li>
            Attitude — the kind of job they are searching for — just a nice place to sit during the 9 to 5, or specifically a place to <a href="/blog/bounce" title="Bounce the ball">reach personal and company goals — tackle challenges</a>.
          </li>
          <li>
            Communication skills — the way they communicate their ideas and feelings with everyone around.
          </li>
        </ul>
        <p>
          At a small scale it would look like the most important requirement is the technical fit. And that would be true. Up to a certain point. Up to the point where they would not be the only people who are building the product. Up to the point where there has to be some collaboration and brainstorming. At that point and going forward the most important requirement I have found is the cultural fit (or a mix of technical and cultural fit).
        </p>
        <p>
          I’ve worked with a lot of very <a href="/blog/dysfunctional" title="Dysfunctional programmers">good engineers who were just plain toxic</a>. It would seem that it’s possible to work around the bad culture fit for the sake of having good tech solution. But over time any investment of time into trying to work around this problem has never paid off for me. It usually resulted in drastic changes in product teams — replacing the toxic people, <a href="https://hbr.org/2014/09/why-start-ups-replace-early-employees-and-how-to-keep-your-job" rel="noopener noreferrer" target="_blank" title="Resetting the balance">resetting the balance</a> of what’s acceptable and what’s not, and trying to <a href="https://www.forbes.com/sites/theyec/2017/09/14/how-every-startup-should-interview-their-first-hires/#158695f22aa2" rel="noopener noreferrer" target="_blank" title="Restart the culture">restart the great culture</a> — all of which is very hard work and does not scale long term.
        </p>
        <p>
          Start building teams from people with a good cultural and technical mix. Then you can trust that these <a href="/blog/results" title="Results Only Work Environment">people will deliver best value</a> and grow your company’s great culture along the way.
        </p>

        <Tags tags={['Startup', 'Product Development', 'Product Management', 'Product', 'Management']} />
      </article>
      <Breadcrumb title={Article.title} />
    </React.Fragment>
  );
}