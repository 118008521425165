import React, { } from "react";

import Tags from "../../articles/Tags";
import Breadcrumb from "../../articles/Breadcrumb";

import profileSSImg from "../../../assets/img/sarunas-profile.jpeg";
import postImg from "../../../assets/img/posts/founders-800-min.jpg";
import thumbImg from "../../../assets/img/posts/founders-300-min.jpg";

export const Article = {
  alias: 'founders',
  tag: 'culture',
  colour: 'danger',
  blogImg: postImg,
  thumbImg: thumbImg,
  profileImg: profileSSImg,
  author: 'Sarunas Savicianskas',
  posted: 'Mar 27th, 2017',
  title: 'Let’s Talk About Dysfunctional Founders',
  intro: ' I thought I’d share similar ideas about some founders of startups I worked in, or met over the years. Over time some or all of those traits lead to failures and good people leaving their companies. So, let’s talk about Dysfunctional Founders (and CEOs).',
  post: <Post />
};

export default function Post(props) {  
  return (
    <React.Fragment>
      <article className="article">
        <p>
          After writing a list of traits I see very often in some developers which ‘help’ them become <a href="/blog/dysfunctional" title="Dysfunctional Programmers">Dysfunctional Programmers</a>, I received a lot of ‘heat’, probably from said developers.
        </p>
        <p>
          Inspired by that topic, I thought I’d share similar ideas about some founders of startups I worked in, or met over the years. Over time some or all of those traits lead to failures and good people leaving their companies. So, let’s talk about Dysfunctional Founders (and CEOs).
        </p>
        <h3 className="h2">The Boss</h3>
        <p>
          The first trait is very tricky to spot initially, because you need very specific situations for dysfunctional founders to actually show that they are ‘The Boss’. I would draw a clear distinction here between a ‘Boss’ and a ‘Leader’ — so we don’t get confused. So how can you spot ‘The Boss’?
        </p>
        <ol>
          <li>
            The Boss needs to make sure <mark data-aos="highlight-text" data-aos-delay="250">it’s their plan and their decision</mark> to do something — instead of enabling people to present best ideas and making sure that the vision is clear to everyone. Sometimes (actually, very often) it is presented via the technique of turning down a lot of ideas initially, changing one small detail (or nothing in some cases) and presenting it as THE idea from ‘The Boss’.
          </li>
          <li>
            The Boss needs to make sure it’s clear to everyone that at the end of the day <mark data-aos="highlight-text" data-aos-delay="250">everyone is a hired resource</mark>. They will talk about ‘the Team’ and responsibilities which go along with it, but in some situations it will be clearly stated to everyone (or one-to-one).
          </li>
          <li>
            The Boss needs everyone to <mark data-aos="highlight-text" data-aos-delay="250">show 110% commitment</mark> to the company / product in various ways — sitting late in the office, working with impossible deadlines and impossible pivots, going the extra mile every single moment. But when it comes to themselves, the rules change — there can be a lot of long ‘breakfast / lunch meetings’ where nobody knows what is achieved, a lot of early office exits with no explanation, a lot of random meetings out-of-office, which have no impact to the business. In the end — everything The Boss does is work, for no reason.
          </li>
        </ol>
        <blockquote className="blockquote">
          Let’s be clear — 110% commitment comes from a healthy ‘life and work’ balance, not a ‘no life because of work’ balance
        </blockquote>
        <h3 className="h2">Delegation — the lost art</h3>
        <p>
          Sadly, the second trait of a dysfunctional founder is also tricky to spot early, however it shows itself pretty quickly when you start working on specifics things — it’s the art of delegation. Over the years I’ve understood that it takes a strong personality to delegate tasks and ideas properly.
        </p>
        <p>
          You have to actually <mark data-aos="highlight-text" data-aos-delay="250">trust the people hired for the job</mark>. I’ve seen multiple times where a person is hired because of a great track record of actually solving problems or building something awesome, just to be followed by a painful process of showing that person that she or he does not know how to do their job. The ironic part is to then hear the dysfunctional founders complain how bad the said person is at doing their job.
        </p>
        <p>
          You have to admit, that <mark data-aos="highlight-text" data-aos-delay="250">it’s difficult to communicate ideas well</mark>. More often than not, it takes a lot of time to describe ideas and concepts. It’s almost never just a simple two sentence email. I have seen a lot of dysfunctional founders who had to be in every single step of the process, handholding and explaining to everyone ‘how it actually should be done’ instead of sitting down, thinking and writing down a proper idea specification.
        </p>
        <p>
          You have to be able to <mark data-aos="highlight-text" data-aos-delay="250">delegate the fame of a good execution</mark> also. Dysfunctional founders need to be in the process, otherwise they are not getting attributed for it. The product is no longer theirs.
        </p>
        <h3 className="h2">Listens to Everyone</h3>
        <p>
          The ‘scariest’ trait of dysfunctional founders is the fact they see every single piece of feedback as valuable feedback, which is not usually the case. Receiving constructive and detailed feedback is very critical when building products, but it has to be viewed critically.
        </p>
        <p>
          There are a couple of categories of feedback, which have to be viewed very critically indeed:
        </p>
        <ol>
          <li>
            Relatives, friends and significant others. They are the people who will most likely care hugely about the success of the product the dysfunctional founders are building. But at the same time they are almost <mark data-aos="highlight-text" data-aos-delay="250">never in the target user group</mark>, which will use the product. They are almost always not very good at expressing their ideas — so the feedback they give is often a comparison to some product which is already on the market and does something totally different, but ‘looks cool’ or ‘really works’. Dysfunctional founders actually put a priority on that kind of feedback.
          </li>
          <li>
            Investors who are usually <mark data-aos="highlight-text" data-aos-delay="250">investing at a different stage</mark> than your startup (e.g. late stage investors vs early stage startup) or investors who do not specialise (invest) in the market at which your product is targeted. One perfect example here is VC funds who falsely advertise as seed investors, but never actually do seed investments (only late stage investment). They usually have only a couple of questions about the startup and execution, but a ton of questions about growth, ROI, YoY, Churn rates, even though you have yet to build your initial MVP or a product. Dysfunctional founders actually listen to them — they create massive Excel spreadsheets with fantastic growth charts, even change what their product is about. Just to be asked the following question — “what actual user metrics do you have to back this up?”
          </li>
          <li>
            <mark data-aos="highlight-text" data-aos-delay="250">The ‘know-it-alls’</mark>. They usually have an opinion about everything in the product — the UX, the features, the business model, the technologies used, the target market, the competitors — everything — and they sound very convincing. After receiving feedback from a ‘know-it-all’ some dysfunctional founders sometimes completely rethink what product they’re building — it can be scary. Worse still is when a dysfunctional founder hires them as an ‘advisor’!
          </li>
        </ol>
        <h3 className="h2">Best work — with mind readers</h3>
        <p>
          And the ‘best’ trait the dysfunctional founders have is the constant <mark data-aos="highlight-text" data-aos-delay="250">inability to express themselves</mark> correctly. Usually it means that one story is told when they are describing ideas in a high level discussion, and a completely different story is told in the details, when talking about specifics.
        </p>
        <p>
          Over time the whole team <mark data-aos="highlight-text" data-aos-delay="250">learns to ‘understand’</mark> what the dysfunctional founders mean by A or B. They go from having a healthy work environment to a game of ‘what did they mean by X and Y?’. If they win, they get to execute the ideas. If they don’t — they can at least write in their resume ‘mind reading skills = poor’. And get blamed for wrong execution.
        </p>

        <Tags tags={['Life Lessons', 'Startup', 'Product Development', 'Productivity', 'Motivation']} />
      </article>
      <Breadcrumb title={Article.title} />
    </React.Fragment>
  );
}