import React, { } from "react";

export default function HireFooter(props) {  
  return (
    <React.Fragment>
      <section className="pb-0" style={{ paddingTop: "0" }}>
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-7">
              <h3 className="display-4">Need help?</h3>
              <div className="lead">I help founders &amp; companies build products + scale tech &amp; product teams.</div>
            </div>
          </div>
          <div className="row justify-content-center text-center mt-md-n4">
            <div className="col-auto">
              <a href="mailto:sarunas@sarunas.co.uk" className="btn btn-primary btn-lg">Get in touch</a>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}