import React, { } from "react";

import Tags from "../../articles/Tags";
import Breadcrumb from "../../articles/Breadcrumb";

import profileSSImg from "../../../assets/img/sarunas-profile.jpeg";
import postImg from "../../../assets/img/posts/low-800-min.jpg";
import thumbImg from "../../../assets/img/posts/low-300-min.jpg";

export const Article = {
  alias: 'low',
  tag: 'management',
  colour: 'primary',
  blogImg: postImg,
  thumbImg: thumbImg,
  profileImg: profileSSImg,
  author: 'Sarunas Savicianskas',
  posted: 'Feb 17th, 2020',
  title: 'Low hanging fruits kill innovation',
  intro: 'Some of my thoughts regarding one term which I keep hearing — low hanging fruits (when it means getting quick wins either starting a new role or starting a new project)',
  post: <Post />
};

export default function Post(props) {  
  return (
    <React.Fragment>
      <article className="article">
        <p>
          Here’s a short story for you. It’s somewhat hypothetical.
        </p>
        <blockquote className="blockquote">
          We created an MVP — something for ourselves. Something hopefully others also need. There’s a market fit for that.
          <br /><br />
          Started showing it to customers. One by one.
          <br /><br />
          It’s not scalable: the whole team (all 2, 3, 5 of us) is working to make sure that every customer goes through the funnel successfully (even if it’s just two steps). Some of them do. In fact, a lot of them do. But they all are very similar...
          <br /><br />
          Contacted some of the customers to help them out. One by one. They appreciated it.
          <br /><br />
          Added a button to make it easier. We heard it’s needed from the customers we had contacted. Added another button. Replaced two buttons with a form.
          <br /><br />
          One customer a day became one hundred customers a day. With not much changes in the product. Whoa! That’s awesome!
          <br /><br />
          We celebrated it. Then went back to work.
          <br /><br />
          The one hundred is not becoming two hundreds with the same growth rate. The current funnel is becoming more of a funnel you would see in the marketing optimisation guidebooks. Also, customers no longer look similar. We should do something.
          <br /><br />
          We need to speed up the growth. We’ve grown it so far with not much changes in the product anyway, right? Creative meeting about more growth started.
          <br /><br />
          We don’t have much resources. Product has a market fit already.
          <br /><br />
          How do we improve it? With the resources we have.
          <br /><br />
          Let’s change something simple and obvious. Maybe it will have a huge impact.
          <br /><br />
          That worked! Let’s do more of that.
          <br /><br />
          Hmm, that didn’t work, but maybe users just didn’t understand it. It’s their fault.
          <br /><br />
          We should try something again to grow more. Remember the time when we did that simple change and it worked? Let’s do that again.
          <br /><br />
          It worked again! We don’t need to talk to customers anymore, it’s obvious.
          <br /><br />
          We should do more of these small changes. Maybe some of them will bring bigger changes.
          <br /><br />
          We are very busy. Some of the small things are taking a bit more time. We didn’t think them through too much. Why are we doing these huge changes anyway?
          <br /><br />
          We should go back to small changes, that used to bring quick results.
        </blockquote>

        <h3 className="h2">Low hanging fruits</h3>
        <p>
          Here are some of my thoughts regarding one term which I keep hearing — <mark data-aos="highlight-text" data-aos-delay="250">low hanging fruits</mark> (when it means getting quick wins either starting a new role or starting a new project). It’s a way to build authority in a new position. It’s also a way to get some results with a project which has yet to be finished. It’s a great concept in abstract, but in my opinion has it’s disadvantages when it becomes the driver for everything. Especially if it becomes the driving strategy of the Team (or worse — the Business).
        </p>
        <p>
          It’s very important to know what is the Business strategy (or agree on it, if that’s not yet clear). It’s also important to be aligned on the strategy. It’s important <mark data-aos="highlight-text" data-aos-delay="250">because strategy defines tactics (=what do we do to push the Business forward daily). Tactics should not define strategy</mark>.
        </p>
        <p>
          During the last couple of startups I worked in I have come to a realisation that chasing low hanging fruits as tactics kills innovation. Let’s go through what I mean.
        </p>
        <h3 className="h2">Constantly busy and getting busier</h3>
        <p>
          Low hanging fruits are great as a concept. You get quick wins. You get things done. You try to define a small scope for the sake of shipping stuff quickly. But then all those small scopes, small changes, small improvements — they become bigger (there’s always something out of scope), they add up to bigger block of things to do — and magically you’re busy. Constantly busy and getting even busier.
        </p>
        <p>
          Being busy does not mean that all of those low hanging fruits magically end. No. Usually once you’re in the mood of creating quick and small iterations — you end up with a huge list. The list is usually influenced by a lot of great things other companies and products are getting done (and getting results). And it becomes hard to say stop, since you’re so busy working on all of those low hanging fruits, because they just look so obvious, so easy and so small. And you become busier.
        </p>
        <p>
          With the tactics of getting the low hanging fruits done first leading the strategy, it also pushes everyone new in the company (or project) to suggest their somewhat obvious quick wins. Even before building the knowledge and specifics about the business. Since it becomes a shortcut to building authority and starting projects with a couple of quick wins, the suggestion engine starts running even faster for the list of quick wins. Suddenly everything you’re doing is working on all of those small wins. Nothing gets too much time to actually stop, think, analyse. You’re stuck being unable to create more meaningful (or even data driven) improvements.
        </p>
        <h3 className="h2">Gambler vs innovator mindset</h3>
        <p>
          Low hanging fruit is almost always obvious (especially with some anecdotal assumptions) and almost always have a quick return. They lead to a thought process that everything should be obvious. It becomes almost like gambling. Gambling looks very easy and has obvious returns when specific (usually simple) conditions are met. It slowly leads to a thought process that everything should be simple and have quick returns. If anything requires too much involvement or thought process, it no longer is interesting and does not give quick gratification. But similar to gambling — low hanging fruits usually give small returns for small (or none) risk, and almost never work out for big return, because it comes with an improbable possibility (=huge risk) to actually work out beyond the said anecdotal assumptions.
        </p>
        <p>
        You’ve probably seen a lot of such “obvious” changes/features which have quick return. It starts from creating a button to remove 5 steps from the process (e.g. “<a href="http://make-everything-ok.com/" rel="noopener noreferrer" target="_blank" title="Make Everything OK">Make Everything OK</a>” or “I’m feeling lucky”). Then casually flooding all of your users with special offers (maybe even weekly or dare I say daily?), just because that one specific offer helped you convert a small group of users into paying customers. Then making your whole premium product free with the hope that someone will choose to still pay for the premium, just because some other company posted a blog post saying that it saved their business from collapsing. Congratulations — <mark data-aos="highlight-text" data-aos-delay="250">you’re a gambler</mark>!
        </p>
        <p>
          The simple reality is that there is no such thing as low hanging fruits which bring big returns. Innovation brings big returns. And <mark data-aos="highlight-text" data-aos-delay="250">innovation requires time or creative thinking. Time to think and do research. Time to test ideas, validate with the market and go beyond the anecdotal situations</mark>. And it does become obvious after innovation happens, because it usually is simple enough and has a great market fit.
        </p>
        <p>
          By focusing on low hanging fruits a damaging thing happens — you start to focus on doing (because it’s so easy to keep shipping changes, ideas, improvements — they’re small and obvious, right?). Instead of taking the time to think, talk, discuss, argue, test and eventually innovate.
        </p>
        <h3 className="h2">Innovation happens when challenged</h3>
        <p>
        Since most people usually have <a href="https://www.sciencedaily.com/terms/confirmation_bias.htm" rel="noopener noreferrer" target="_blank" title="Confirmation bias">confirmation bias</a>, it leads most of us to think that by doing more of those low hanging fruits we are actually achieving more. In reality we’re busy doing small scale gambling. And small scale gambling has only small scale possible returns.
        </p>
        <p>
          Innovation is completely different — it’s problems — needing to be solved. Every attempt to solve each problem is just a hypothesis which needs validation first (not confirmation). Talking about problems becomes the starting point. Thinking and creating specific hypotheses becomes a priority. Testing/Validating those hypotheses becomes secondary priority after that. “Doing” becomes a tertiary priority. The gambler mindset is replaced by innovator mindset. Innovator mindset starts searching for the biggest fruit (business potential) possible instead of low hanging fruit. That requires challenging every single assumption and anecdotal information. It requires “thinking — testing — thinking” loop to keep happening daily.
        </p>
        <p>
          Eventually all the low hanging fruits become a distraction. And the process of creating hypotheses, testing them, learning and creating innovation becomes the norm. But that’s a pretty difficult task in itself. Definitely not as easy as going for the low hanging fruits.
        </p>
        <p>
          Either way, I challenge you to re-evaluate your approach and thought process — remove the gambler in you and become an innovator! Because at the end of the day creating something truly innovative is going to feel much more rewarding than going for the low hanging fruits.
        </p>
        <p>
          And isn’t that what life is truly all about?
        </p>

        <Tags tags={['Startup', 'Entrepreneurship', 'Business', 'Data Science', 'Product Management']} />
      </article>
      <Breadcrumb title={Article.title} />
    </React.Fragment>
  );
}