import React, { } from "react";

import Tags from "../../articles/Tags";
import Breadcrumb from "../../articles/Breadcrumb";

import profileSSImg from "../../../assets/img/sarunas-profile.jpeg";
import postImg from "../../../assets/img/posts/power-800-min.jpg";
import thumbImg from "../../../assets/img/posts/power-300-min.jpg";

export const Article = {
  alias: 'power',
  tag: 'product',
  colour: 'warning',
  blogImg: postImg,
  thumbImg: thumbImg,
  profileImg: profileSSImg,
  author: 'Sarunas Savicianskas',
  posted: 'Aug 30th, 2017',
  title: 'Listen to Power Users',
  intro: 'They are very special kind of users — they are hungry for new products, they are hungry for innovations and see the fact that some part of their life is going to change (for the better) as a welcoming challenge.',
  post: <Post />
};

export default function Post(props) {  
  return (
    <React.Fragment>
      <article className="article">
        <p>
          Once you get past <a href="/blog/self" title="Don't build a Product for yourself">building products just for yourself</a> you’ll actually need some real users (who are not your F&F). You’ll need the select users who will most likely help you shape your product — the Power Users. They are very special kind of users — they are hungry for new products, they are hungry for innovations and see the fact that some part of their life is going to change (for the better) as a welcoming challenge.
        </p>
        <p>
          You will most likely have to randomly appear on their radar or introduce your product in a nice way for them to pick it up. But once they pick your product up, understand and start using it, they will be like additional team members — they will forgive a lot of bugs and initial problems, because they are adopters of the core concept of your product, not the details.
        </p>
        <p>
          They will help you identify the bugs and then praise you with respect for continuous improvements. They will continue giving you specific feedback and improvements which they expect to appear. They will appreciate nice surprises if you release something new also.
        </p>
        <p>
          One very nice thing with Power Users is the fact that they become your brand ambassadors just because they like your product. They will celebrate your wins as if they are part of your company. They will ‘defend’ you in message boards. They will explain to new users how to use your product best.
        </p>
        <h3 className="h2">…all of your Power Users</h3>
        <p>
          Very often the Power Users who start using your product are not what you might initially expect them to be. You might always expect them to be quite young, tech savvy and ready for innovations. But you might be wrong.
        </p>
        <p>
          They might differ by age group, by geography, by use case of your product. You should accept all of that and identify what they say by getting feedback and what they do by tracking usage.
        </p>
        <p>
          I’ve had very interesting use cases in one startup where our product was helping people order food from finest restaurants in London. We had some users ordering food every single day, with multiple orders on some days. The ROIs were absolutely insane — we even had to exclude them from our calculations in some cases. Our assumption was a bit stereotypical — definitely all of them are tech savvy engineers, who are just lazy. But to our surprise all of them were very random — not from tech world, not millennials, not on very high salaries. Some of them were security guards, who found it easier to order the food delivered to save time and get it hotter than they would normally get it if they went out to get food themselves. Some were office managers, who found it very easy to plan food deliveries for the exact same reasons. The funny thing was that even tough they were not tech savvy, they were our brand ambassadors, because they were spreading the word of mouth. We even looked into our order heat-map and it nicely showed hotter order spots around our unexpected Power Users.
        </p>
        <p>
          But along with Power Users there is another type of users which I like to call ‘fake geeks’ or ‘fake brand ambassadors’. It’s an interesting new ‘breed’ of users which have an image of being involved in a lot of activities, having a very strong opinion about tech and product solutions, and somehow attracting some followers. I’ve had the ‘pleasure’ of seeing such ‘fake geeks’ in action and it’s almost like a new age scam. I remember in one company we hired such a person as a growth marketing person. It seemed like a perfect fit — so much experience, such a strong opinion and so much involvement in a lot of activities. But then the results never came — the person was constantly busy, constantly talking about ‘trends’, about something new, something not related to helping our product grow. It was a pain to realise the mistake we made.
        </p>
        <p>
          Listen to your Power Users.
        </p>

        <Tags tags={['Startup', 'Product Development', 'Product Management', 'Product Design', 'Entrepreneurship']} />
      </article>
      <Breadcrumb title={Article.title} />
    </React.Fragment>
  );
}