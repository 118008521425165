import React, { } from "react";
import { useLocation } from "react-router-dom";

import { ReactSvgInjector } from "react-svg-injector";

import twitterIcon from "../../assets/img/icons/social/twitter.svg";
import facebookIcon from "../../assets/img/icons/social/facebook.svg";
import linkedinIcon from "../../assets/img/icons/social/linkedin.svg";

export default function ShareArticle(props) {
  const location = useLocation();
  const URI = encodeURIComponent(`sarunas.co.uk${location.pathname}`);

  return (
    <React.Fragment>
      <div className="my-4 my-sm-5 card card-body flex-sm-row justify-content-between align-items-center">
        <div className="h5 mb-sm-0">If you enjoyed this article, please share:</div>
        <div className="d-flex">
          <a target="_blank" href={`//twitter.com/share?text=Check%20out%20this%20article%20by%20@s2sarunas&url=${URI}&hashtags=@s2sarunas,#technology,#management`} rel="noopener noreferrer" className="btn btn-sm btn-primary rounded-circle mx-1">
            <ReactSvgInjector src={twitterIcon} alt="Twitter" className="icon icons-m bg-white" />
          </a>
          <a target="_blank" href={`//www.facebook.com/sharer/sharer.php?u=${URI}`} rel="noopener noreferrer" className="btn btn-sm btn-primary rounded-circle mx-1">
            <ReactSvgInjector src={facebookIcon} alt="Facebook" className="icon icons-m bg-white" />
          </a>
          <a target="_blank" href={`//www.linkedin.com/shareArticle?url=${URI}`} rel="noopener noreferrer" className="btn btn-sm btn-primary rounded-circle mx-1">
            <ReactSvgInjector src={linkedinIcon} alt="Linked In" className="icon icons-m bg-white" />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}