import React, { } from "react";

import Tags from "../../articles/Tags";
import Breadcrumb from "../../articles/Breadcrumb";

import profileSSImg from "../../../assets/img/sarunas-profile.jpeg";
import postImg from "../../../assets/img/posts/dysfunctional-800-min.jpg";
import thumbImg from "../../../assets/img/posts/dysfunctional-300-min.jpg";

export const Article = {
  alias: 'dysfunctional',
  tag: 'culture',
  colour: 'danger',
  blogImg: postImg,
  thumbImg: thumbImg,
  profileImg: profileSSImg,
  author: 'Sarunas Savicianskas',
  posted: 'Jan 9th, 2017',
  title: 'Let’s talk about Dysfunctional Programmers',
  intro: 'One of those keywords, and topics, following Agile, has been stuck in my head for quite some time now — the dysfunctional team.',
  post: <Post />,
};

export default function Post(props) {  
  return (
    <React.Fragment>
      <article className="article">
        <p>
          Let’s talk about some nice keywords people in the tech world like to throw around. Let’s talk Agile and all the nice processes it offers to ignore putting processes over people. One of those keywords, and topics, following it, has been stuck in my head for quite some time now — the dysfunctional team. It’s a very nice topic to discuss and to try to understand to help any team. I won’t go over what it’s about and what it offers — you can look it up, and, possibly, it will get you thinking also. It got me thinking about the teams I’ve worked with over the years.
        </p>
        <p>
          I’ve been working in various businesses (startups and corporates) for quite some time now — coding, creating architectures to scale or MVPs to prove a concept. I’ve noticed a couple of traits, which some engineers have and are quietly nurturing. It’s not about the teams or “dysfunctional teams”, but more about the engineers, or “dysfunctional programmers” as I would like to call them. It’s not a list to define them, but a list which pops out when I think about that the term.
        </p>
        <h3 className="h2">What do successful dysfunctional programmers do over and over?</h3>
        <h5>1. They don’t like specs</h5>
        <p>
          It’s not about liking or not liking the specifications per se. It is about the fact that engineers like to create solutions to problems. That’s very cool, because their passion for work comes from solving problems.
        </p>
        <p>
          Dysfunctional programmers actually start planning the solutions and answers in their heads, without even looking into specs. Over time it leads to a lot of situations where features and solutions are constantly over-engineered, everything takes too much time and there’s a constant conflict between “the developers” and “the business”.
        </p>
        <h5>2. They always build for massive success</h5>
        <p>
          It’s definitely very important to understand and build good solutions to specific problems. But that doesn’t mean that every single solution has to scale or scale from day one.
        </p>
        <p>
          Dysfunctional programmers like to think that every single product or feature will be used by millions of users. There is no scenario where the first iteration could actually be used by a couple of customers / users. There is no point in allowing the product to show which parts need to scale and which do not, because everything NEEDS to scale.
        </p>
        <h5>3. They build solutions upfront, instead of presenting ideas</h5>
        <p>
          It’s very important to have initiatives — it brings new and out-of-the-box ideas. Those ideas sometimes push startups to the point where pivots happen by pinpointing the solution to the problem they are trying to solve. Usually that involves good communication and talking through ideas to become solutions.
        </p>
        <p>
          Dysfunctional programmers do not like talking through ideas. Or, what usually happens, they present it once, communicate the idea poorly and don’t get the instant hype they expect. That leads to them choosing to close off from the world and BUILD or, most likely, rush through other tasks to spend as much time on building a solution based on the presented idea.
        </p>
        <p>
          (This is definitely not a full list, but you get the point.)
        </p>
        <h3 className="h2">Do you work with a Dysfunctional Programmer?</h3>
        <p>
          Most likely you have worked or still work with someone who is a dysfunctional programmer. Very likely you are or have been (or will be) a dysfunctional programmer at some point in your career (I know I’m not only pointing fingers when I’m writing down these bullet points). They are very often pretty good technical people also. They are very often very important to their companies — the Heroes who save the day. It’s usually a mix of luck and the fact that customers and businesses do not know themselves what they want to build.
        </p>
        <p>
          Look out for them — they are followed by pretty solid egos. They can teach you a lot of good things technically (up to a point), but they can damage you professionally also, because they <mark data-aos="highlight-text" data-aos-delay="250">never fail</mark> and <mark data-aos="highlight-text" data-aos-delay="250">know everything already</mark>. It’s exactly for these reasons I also call them team destroyers.
        </p>
        <h3 className="h2">What then?</h3>
        <ol>
          <li>
            Try to spot them early and don’t get overhyped by the results they (sometimes) bring.
          </li>
          <li>
            Build teams which can translate the business requirements to simple solutions.
          </li>
          <li>
            Build solutions for the scale which is needed at a specific point.
          </li>
          <li>
            Build teams which can communicate ideas well enough to be able to build together.
          </li>
        </ol>

        <Tags tags={['Tech', 'Startup', 'Web Development', 'Product Development', 'Startup']} />
      </article>
      <Breadcrumb title={Article.title} />
    </React.Fragment>
  );
}