import React, { useEffect, useState, Suspense } from "react";
import MetaTags from 'react-meta-tags';

import ShareArticle from '../components/cta/ShareArticle';
import HireFooter from "../components/cta/HireFooter";

// posts
import { Article as DysfunctionalArticle } from '../components/posts/dysfunctional/Post';
import { Article as BounceArticle } from '../components/posts/bounce/Post';
import { Article as ResultsArticle } from '../components/posts/results/Post';
import { Article as FoundersArticle } from '../components/posts/founders/Post';
import { Article as SelfArticle } from '../components/posts/self/Post';
import { Article as PowerArticle } from '../components/posts/power/Post';
import { Article as LoveArticle } from '../components/posts/love/Post';
import { Article as LeadersArticle } from '../components/posts/leaders/Post';
import { Article as OverArticle } from '../components/posts/over/Post';
import { Article as BestArticle } from '../components/posts/best/Post';
import { Article as LowArticle } from '../components/posts/low/Post';

// post array
export const blogPosts = {
  'dysfunctional': DysfunctionalArticle,
  'bounce': BounceArticle,
  'results': ResultsArticle,
  'founders': FoundersArticle,
  'self': SelfArticle,
  'power': PowerArticle,
  'love': LoveArticle,
  'leaders': LeadersArticle,
  'over': OverArticle,
  'best': BestArticle,
  'low': LowArticle,
};

const renderLoader = () =>
<div className="load__icon-wrap text-center">
  <svg className="load__icon">
    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
  </svg>
</div>;

export default function BlogPage(props) {
  const [post, setPost] = useState(null);

  useEffect(() => {
    async function onLoad() {
      try {
        setPost(blogPosts[props.match.params.alias]);
      } catch (e) {
        console.log('error loading', e);
        props.history.push("/");
      }
    }

    onLoad();
  }, [props.match.params.alias, props.history]);

  return <Suspense fallback={renderLoader()}>
      {post && <React.Fragment>
        <MetaTags>
          <title>{post.title} by Sarunas</title>
          <meta name="description" content={post.intro} />
          <meta property="og:description" content={post.intro} />
          <meta property="og:title" content={`${post.title} by Sarunas`} />
          <meta property="og:image" content={post.blogImg} />
          <meta property="og:url" content={`//sarunas.co.uk/blog/${post.alias}`} />
        </MetaTags>

        <section className="p-0 border-top border-bottom bg-light row no-gutters">
          <div className="col-lg-5 col-xl-6 order-lg-2">
            <div className="divider divider-side transform-flip-y bg-light d-none d-lg-block"></div>
            <img className="flex-fill" src={post.blogImg} alt={post.title} />
          </div>
          <div className="col-lg-7 col-xl-6">
            <div className="container min-vh-lg-70 d-flex align-items-center">
              <div className="row justify-content-center">
                <div className="col col-md-10 col-xl-9 py-4 py-sm-5">
                  <div className="my-4 my-md-5">
                    <div className="d-flex align-items-center mb-3 mb-xl-4">
                      <div className="ml-3 text-small text-muted">{post.posted}</div>
                    </div>
                    <h1 className="display-4">
                      {post.title}
                    </h1>
                    <a href="/" className="d-flex align-items-center">
                      <img src={post.profileImg} alt={`${post.author} profile`} className="avatar avatar-sm" />
                      <div className="h6 mb-0 ml-3">{post.author}</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-10 col-md-10">
                {post.post}

                <ShareArticle />
              </div>
            </div>
          </div>
        </section>

        <HireFooter />
      </React.Fragment>}
    </Suspense>;
}