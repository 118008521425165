import React, { } from "react";

export default function Breadcrumb(props) {
  const { title } = props;

  return (
    <div className="mt-4 mt-sm-5 border-top pt-5 d-none d-sm-block">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">Articles</li>
          <li className="breadcrumb-item">{title}</li>
        </ol>
      </nav>
    </div>
  );
}