import React, { } from "react";

export default function Tags(props) {
  const { tags } = props;

  return (
    <div className="d-flex flex-wrap  text-center">
      {tags.map(tag => {
        return <div key={tag} className="mr-2 mb-2">
          <div className="badge badge-info">{tag}</div>
        </div>
      })}
    </div>
  );
}