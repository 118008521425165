import React, { } from "react";

import Tags from "../../articles/Tags";
import Breadcrumb from "../../articles/Breadcrumb";

import profileSSImg from "../../../assets/img/sarunas-profile.jpeg";
import postImg from "../../../assets/img/posts/results-800-min.jpg";
import thumbImg from "../../../assets/img/posts/results-300-min.jpg";

export const Article = {
  alias: 'results',
  tag: 'management',
  colour: 'primary',
  blogImg: postImg,
  thumbImg: thumbImg,
  profileImg: profileSSImg,
  author: 'Sarunas Savicianskas',
  posted: 'Feb 28th, 2017',
  title: 'Results Only Work Environment',
  intro: 'Results Only Work Environment or ROWE is a term which I have seen being mentioned in some articles and it got me thinking about a lot of situations where I have been in an anti-ROWE environment.',
  post: <Post />
};

export default function Post(props) {  
  return (
    <React.Fragment>
      <article className="article">
        <p>
          Results Only Work Environment or ROWE is a term which I have seen being mentioned in some articles and it got me thinking about a lot of situations where I have been in an anti-ROWE environment. You probably have seen companies or teams which also have that.
        </p>
        <p>
          Here are a few Symptoms:
        </p>
        <ol>
          <li>
            You have meetings upon meetings without any goal. There’s no agenda, or only one person has the agenda and everyone else in the meeting is just a listener. You cannot come prepared to that kind of meeting, because you don’t know what to prepare. You also cannot add enough of your input to the meeting, without spending a vast amount of the time in the meeting actually discussing what that meeting is about. You also cannot focus on a meeting result. I will not be ashamed to say that I have fallen asleep (or close to that) in a couple of meetings like that.
          </li>
          <li>
            You have Team Building Exercises, but no follow up with some change or result. I have seen very good and interesting topics raised and discussed in a lot of team retrospective discussions only to see no action taken afterwards. After some time having the team building exercises itself becomes another meeting with no agenda. Zzz, again.
          </li>
          <li>
            You like to build just for the sake of building or getting to know a new technology. This is like a new crush which fades as fast as it starts. When you build something just because it’s interesting to you to learn the technology, but the end result and the value it will bring doesn’t matter to you, you focus on the technology. You are very motivated for a short time, but then you are almost instantly demotivated, because the crush is gone.
          </li>
          <li>
            Your responsibilities and KPIs are built around ‘how you work’ rather than the ‘result you deliver’. If you’re a Customer Support Agent, it’s about the number of calls you take, rather than how many problems you solve for the customers. If you’re an Engineer, it’s about a number of features you release, rather than how much value all of those features (or just a single one) deliver. Over time you start to see yourself as that KPI number. That demotivating KPI number.
          </li>
        </ol>
        <p>
          All of those symptoms follow the same idea — they do not focus on the results. Some will focus on following the process perfectly. Some will focus on doing the most amount of repetitive tasks in a short amount of time. But none of them will focus on results only — hence the anti-ROWE.
        </p>
        <p>
          Defining a healthy ROWE is not an easy task itself, but the goal has to be clear — your responsibilities and KPIs are focused on the results — the added value.
        </p>
        <h3 className="h2">Use Data for Results, not Vanity Metrics</h3>
        <p>
          First of all, if your Company is not obsessed about data and metrics, then it should start there — otherwise you’re blind and anything you define as a goal or result will be very random and un-trackable. You will have ‘Hunch Driven Development’ (yeah, there’s no such term, I know), which will be very tedious to explain if it does not bring results.
        </p>
        <p>
          But if you’re already ‘crazy’ about data, then you have one simple task left — try not to focus on vanity metrics as an indicator for result tracking.
        </p>
        <ol>
          <li>
            Don’t focus <mark data-aos="highlight-text" data-aos-delay="250">just on the number</mark> of visits / likes / follows, because I can almost bet that there are websites which offer getting you a million or two extra visits for a very cheap price. <mark data-aos="highlight-text" data-aos-delay="250">Focus on the value</mark> those visits get you — your result should be somewhere beyond vanity metrics, beyond something which does not translate to the company or team growth.
          </li>
          <li>
            Don’t focus on sending out the most emails in the company — send <mark data-aos="highlight-text" data-aos-delay="250">the one</mark> which will bring the best result.
          </li>
          <li>
            Try to <mark data-aos="highlight-text" data-aos-delay="250">identify your vanity metrics early</mark> — you’ll have less discussions over and over again regarding the definition of a vanity metric in your data store.
          </li>
          <li>
            Try to <mark data-aos="highlight-text" data-aos-delay="250">minimise the number of key metrics</mark> in general — a result should not be based on multiple KPIs. Having multiple metrics as a result indicator will bring more negative results in the long term. Set your goal on one specific metric, then the result will be easier to comprehend.
          </li>
        </ol>
        <h3 className="h2">The Value of +1%</h3>
        <p>
          Get into the habit of trying to get steady positive results. Not just sporadic huge wins from time to time, but steady positive results. Look at it as a stock market where a specific stock is bringing you a steady +1% month on month. It might look like not much to some people, but it is a <mark data-aos="highlight-text" data-aos-delay="250">constant positive growth which over time will bring much better results</mark> compared to a stock which performs in the range from -20% to +20%.
        </p>
        <p>
          When you have a project, try to break it into smaller pieces, which would need to bring some result — add value with each piece. You will see that some pieces of the project might not bring any value at all, some might bring a lot of value, some small amount of value. Then think about those pieces and answer yourself — are they really needed? You already indicated that they will probably not add value. The next step is to agree that they are not needed at all.
        </p>
        <h3 className="h2">Team &gt; Sum of Members</h3>
        <p>
          Another good habit is to try and achieve better overall results as a team compared to the sum of individual team member’s results. This does not mean that you won’t have Most Valuable Players from time to time, but it does mean that when looking at overall results you should all achieve more than you would achieve if you were all working individually on your own tasks and challenges. Otherwise the whole concept of the team loses any value or need.
        </p>
        <p>
          I have a perfect example how that works in real life. We had two product teams working on fairly similar intensity roadmaps — one was relaxed and delivering every task on time, the other one was always playing catch-up and a bit stressed. Obviously I wanted both of them to be relaxed and deliver everything on time. I reviewed the roadmaps — they both looked similar. Then I looked into how much every single person in the first team was contributing to the roadmap and tasks delivered versus the individual results in the second team. It was quite obvious — we had a team member in the second team who was always busy with himself, and other team members had to constantly pick up the tasks which he did not deliver. They were doing a good job ‘covering’ their colleague without even knowing it. But slowly it was increasing their overall level of stress. The sum of their team contributions was probably close to what they could have achieved individually, but at what price?
        </p>
        <h3 className="h2">Everyone Understands the Why?</h3>
        <p>
          The last habit which I think glues the ROWE is the greater goal of the company — the “Why?”. I have seen many developers (and people not specifically working in development) being great at what they do, but not caring about why they are doing a task or working on a project. They were doing the 9 to 5, demanding everything to be synthesised down to a simple task level (which is still fine and how it should be), but did not care about the end result any of their work had on the company.
        </p>
        <p>
          A lot of developers like to aim for the “Senior” or “Lead” keyword in their title, but not many of them actually know that that keyword has little to do with actual development skills or the time they have been working as a developer. The ‘seniority’ comes from going beyond that — it comes from caring about the greater goal a company is trying to achieve. It comes from asking a question “Why are we creating / improving this product?”, understanding the answer and working together with the team to achieve the “Why?”.
        </p>
        <h3 className="h2">ROWE — The Formula</h3>
        <p>
          So, remember:
        </p>
        <blockquote className="blockquote">
          <mark data-aos="highlight-text" data-aos-delay="250">ROWE</mark> = ‘Data’ + ‘+1%’ + (‘Team’ &gt; Sum(Members)) + “Why?”
        </blockquote>
        <p>
          Who doesn’t like formulas?
        </p>

        <Tags tags={['Startup', 'Metrics', 'Agile', 'Product Management', 'Lessons Learned']} />
      </article>
      <Breadcrumb title={Article.title} />
    </React.Fragment>
  );
}