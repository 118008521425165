import React, { } from "react";

import Tags from "../../articles/Tags";
import Breadcrumb from "../../articles/Breadcrumb";

import profileSSImg from "../../../assets/img/sarunas-profile.jpeg";
import postImg from "../../../assets/img/posts/leaders-800-min.jpg";
import thumbImg from "../../../assets/img/posts/leaders-300-min.jpg";
import postImg2 from "../../../assets/img/posts/leaders-emails.png";
import postImg3 from "../../../assets/img/posts/leaders-tweet.png";

export const Article = {
  alias: 'leaders',
  tag: 'leadership',
  colour: 'success',
  blogImg: postImg,
  thumbImg: thumbImg,
  profileImg: profileSSImg,
  author: 'Sarunas Savicianskas',
  posted: 'Sep 21st, 2017',
  title: 'My take on: Leaders are made, not born',
  intro: 'I had a pleasure of participating in the first Vilnius Tech Leads Meetup where the topic for ‘The First One’ was ‘Leadership is something you learn, not something you’re born with’. I shared some of my thoughts about this topic.',
  post: <Post />
};

export default function Post(props) {  
  return (
    <React.Fragment>
      <article className="article">
        <p>
          I had a pleasure of participating in the first <a href="https://www.meetup.com/Vilnius-Tech-Leads/" rel="noopener noreferrer" target="_blank" title="Vilnius Tech Leads">Vilnius Tech Leads Meetup</a> where the topic for ‘The First One’ was ‘<a  href="https://www.meetup.com/Vilnius-Tech-Leads/events/243246629/" rel="noopener noreferrer" target="_blank" title="Leadership is something you learn, not something you’re born with">Leadership is something you learn, not something you’re born with</a>’. I shared some of my thoughts about this topic.
        </p>
        <p>
          I am a strong believer that there are a lot of people who think they are leaders, when they are not, others who think they are not leaders although they are, and a lot of people who could be leaders if they took a couple of extra steps. I have worked with multiple different personalities, who tried to lead their respective companies or teams. I learned a lot from their and my own mistakes along the way.
        </p>
        <h3 className="h2">Stopping self-obsession</h3>
        <p>
          Let’s start with a fact that a lot of people are put into their managerial (or management type) positions with no relevant experience, or they start their own companies at some point with no managing or leadership experience — and they are semi successful at what they do (or sometimes if they are even not). This leads them thinking that they are actually great at what they do. This leads to ego driven mindset and decision making.
        </p>
        <p>
          They start thinking that all of their decisions are good. They start to live in their own little bubbles of truth. They become self-obsessed:
        </p>
        <ul>
          <li>they don’t make bad decisions — by not making any decisions, nor sticking to one single path;</li>
          <li>they know the most important details — by selectively ignoring other information or opinion as not important or plain wrong;</li>
          <li>they motivate everyone all the time just by talking — by saying the same information which is stuck in their self-obsessed information bubble and not actually listening.</li>
        </ul>
        <p>
          This eventually leads to a lot of confused and burned out employees around those ‘managers’. Assuming those employees are critical thinkers, they most likely try to find what they are doing wrong, which leads them into an overthinking loop with no actionable answer and a definite burnout. This leads to employees leaving companies because they don’t know what are they doing right.
        </p>
        <p>
          Happened to me as an employee, almost happened when I started leading some teams myself also.
        </p>
        <h3 className="h2">Becoming self-aware</h3>
        <p>
          The first step to stopping self-obsession and bursting the bubble around it is becoming fully self-aware of what you know and what you don’t know. Nobody knows everything, and even if they do know a lot, nobody makes quick, calculated and correct decisions all the time. That has to be a critical reality.
        </p>
        <p>
          Once people become self-aware of their own limitations (actual skills, knowledge or influence) then every discussion and dialog looses the ego factor — it no longer is a contest of ‘I know everything best’, but actual team work. Then there is no longer one single truth in a room. Then it becomes a team work to know the facts and act accordingly — then the decisions which are made are actually calculated and based on quantitate facts.
        </p>
        <p>
          Then true leadership gets a chance to reveal itself. Delegation starts not only on the action level, but also on the knowledge level.
        </p>
        <h3 className="h2">Removing the emotional</h3>
        <p>
          Becoming self-aware is difficult, because it means people have to acknowledge that they’re not the best at everything. That’s a tedious and emotionally challenging exercise in itself. There has to be a lot of trust within the team. People also need very strong personalities to be able to show that they don’t know every single piece of relevant information. Leaders have to allow people to show that, by starting from themselves.
        </p>
        <p>
          A lot of times it leads to some sort of passive aggression in team communication. I’ve seen a lot of perfect examples of it in email communication. It might be a trolling comment, a defensive statement or a shift in topic itself. Most of them are just emotional expressions which are usually not necessary.
        </p>
        <p className="text-center">
          <img src={postImg2} alt="Passive Aggressive Emails" style={{ width: '100%' }} />
        </p>
        <p>
          That’s one of the reasons a lot of great leaders write short and unemotional messages/emails to their teams — it adds no value and can be interpreted in multiple different ways. There’s no need for interpretations. There’s also no need for the emotional. It’s best to remove the emotional parts completely.
        </p>
        <p className="text-center">
          <img src={postImg3} alt="Twitter message" style={{ width: '100%' }} />
        </p>
        <h3 className="h2">Stop the Overthinking</h3>
        <p>
          Along with the removal of any emotional responses, it’s very important to stop overthinking responses too much. My own experience is that it’s better to have quick back and forth session which shows that I don’t know something and I quickly understand the context, rather than trying to interpret every single word.
        </p>
        <p>
          After having a lot of awkward email threads (continuing on the perfect examples) I’ve arrived to a short rule which I try to use most of the time (especially in high pressure situations):
        </p>
        <ul>
          <li>
            Trust the first instinct on what I know and what I don’t know
          </li>
          <li>
            Don’t trust the first response I write
          </li>
        </ul>
        <p>
          This works very well with ‘bad’ kind of email communication, where there is a chance that I’m giving or receiving criticism, receiving a negative or different response than I’m expecting (or just anything that’s not the way I’m expecting it to be). In those kind of situations the first instinct on why something is not the way I’m expecting is usually right. But my response is usually not correct — it usually has some emotional comment, some defence, something which adds no value, but possibly can be interpreted incorrectly.
        </p>
        <p>
          That’s why the first response goes to draft for a couple of moments. I do some random tasks, then come back, proof read my response, remove the emotional, remove the overthinking and click ‘Send’.
        </p>
        <p>
         I don't think anyone is born with it, but it’s something that’s possible to learn — stopping the self-obsession, becoming self-aware of own limitations, removing the emotional and stopping the overthinking — then leading by example.
        </p>

        <Tags tags={['Leadership', 'Tech', 'Management', 'Teamwork', 'Inspiration']} />
      </article>
      <Breadcrumb title={Article.title} />
    </React.Fragment>
  );
}