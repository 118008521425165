import React, { } from "react";

import Tags from "../../articles/Tags";
import Breadcrumb from "../../articles/Breadcrumb";

import profileSSImg from "../../../assets/img/sarunas-profile.jpeg";
import postImg from "../../../assets/img/posts/self-800-min.jpg";
import thumbImg from "../../../assets/img/posts/self-300-min.jpg";

export const Article = {
  alias: 'self',
  tag: 'product',
  colour: 'warning',
  blogImg: postImg,
  thumbImg: thumbImg,
  profileImg: profileSSImg,
  author: 'Sarunas Savicianskas',
  posted: 'Aug 21st, 2017',
  title: 'Don’t build a product (only) for yourself',
  intro: 'It’s very important to be able to spot problems a lot of people have in the market and put your product in the middle of it as a solution.',
  post: <Post />
};

export default function Post(props) {  
  return (
    <React.Fragment>
      <article className="article">
        <p>
          Once you have some great people with you try to always think about what will your product do. It’s very important to be able to spot problems a lot of people have in the market and put your product in the middle of it as a solution. But at the same time try to actually research how big that problem is and how much would users pay someone for the opportunity to not have that problem. Try not to be the only user who is the perfect user — try not to build a product just for yourself.
        </p>
        <p>
          I have worked in a fair amount of startups, some of which were exactly that — product for very niche users — Power Users being the founders themselves.
        </p>
        <p>
          One very interesting product was built around huge amounts of aggregated commodity data. Very valuable aggregates, with good visual solution to show the trends in data and to help the users make good decisions. The problem? It was absolutely niche product created by a data analyst who did it daily as his job. Basically we automated some part of his work. The work which most of the data analysts trust to do themselves, because they want to be able to play with the raw data. In the end a lot of data analysts said that they like the ‘better visualisation’, but they wanted to actually get access to the raw data, which in most situations they already had. Another problem was that they were not the decision makers in terms of purchasing such data aggregation solutions.
        </p>
        <p>
          Another product which I worked on was a great approach at trying to help bloggers become ‘Editors’ and get paid for their content. It was an interesting concept, much earlier than Medium was born. But it became too complex too soon — the roadmap was filled with so many ‘make this feature which Wordpress already has’ that at some point it just made sense to basically start using Wordpress. Which as I understand was later done. It was a pain to watch the founder try to create a perfect editing platform just to see that, again, nobody is choosing it over Wordpress.
        </p>
        <p>
          Try not to be the only user who is the perfect user. Try not to build a product just for yourself.
        </p>

        <Tags tags={['Startup', 'Product Development', 'Product', 'Product Management', 'Entrepreneurship']} />
      </article>
      <Breadcrumb title={Article.title} />
    </React.Fragment>
  );
}