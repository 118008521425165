import React, { } from "react";

import Tags from "../../articles/Tags";
import Breadcrumb from "../../articles/Breadcrumb";

import profileSSImg from "../../../assets/img/sarunas-profile.jpeg";
import postImg from "../../../assets/img/posts/love-800-min.jpg";
import thumbImg from "../../../assets/img/posts/love-300-min.jpg";

export const Article = {
  alias: 'love',
  tag: 'product',
  colour: 'warning',
  blogImg: postImg,
  thumbImg: thumbImg,
  profileImg: profileSSImg,
  author: 'Sarunas Savicianskas',
  posted: 'Sep 12th, 2017',
  title: 'Why do people love your product?',
  intro: 'Identify it and make sure that is your core competency — invest the majority of time bullet proofing the core.',
  post: <Post />
};

export default function Post(props) {  
  return (
    <React.Fragment>
      <article className="article">
        <p>
          Most likely your product does one key thing which attracts 90% of all the usage. All the additional features are nice, but most likely not something that makes your users stay with your product. Identify it and make sure that is your core competency — <mark data-aos="highlight-text" data-aos-delay="250">invest the majority of time bullet proofing the core</mark>.
        </p>
        <p>
          But don’t focus all of your time on it — don’t invest 100% just on the core. It’s <mark data-aos="highlight-text" data-aos-delay="250">very important to experiment</mark> and invest in something totally crazy. Spend at least 10% of your time on those experiments.
        </p>
        <p>
          There are a lot of companies which were very good at their core competency (<a href="https://hbr.org/2016/07/kodaks-downfall-wasnt-about-technology" target="_blank" rel="noopener noreferrer" title="Kodak">Kodak</a>, for example). But they were focusing purely on the core competency, without investing in crazy ideas, without thinking that it is possible for the market to find easier, crazier or simply different ways to get the same thing they were building or providing with their core competency.
        </p>
        <p>
          Best case is a mix — <a href="https://www.ted.com/talks/knut_haanaes_two_reasons_companies_fail_and_how_to_avoid_them?language=en" rel="noopener noreferrer" target="_blank" title="90% vs 10%">something like 90% core competency, 10% crazy moonshot experiments</a>.
        </p>
        <h3 className="h2">Don’t add features for the sake of having lots of features</h3>
        <p>
          It’s easy to think that a good product needs a lot of features, but every feature means extra maintenance, extra design, extra coding, extra copy. Extra effort, which costs time and money.
        </p>
        <p>
          Start with <mark data-aos="highlight-text" data-aos-delay="250">a problem</mark> which needs solving and <mark data-aos="highlight-text" data-aos-delay="250">a single feature solving that problem</mark>. Do not just look at what your competitors are doing and blindly try to match them.
        </p>
        <p>
          It’s like with a line of furniture stores in one street — one store gets some new style — new sofa, then all of them get the same, because they cannot not have it — and in the end <mark data-aos="highlight-text" data-aos-delay="250">all of them sell exactly the same</mark> things.
        </p>
        <blockquote className="blockquote">
          Don’t copy for the sake of having more features.
        </blockquote>
        <h3 className="h2">Don’t just improve the UI</h3>
        <p>
          When building or improving the product, it’s easy to think that the main thing making the users use your product is the interface. Most likely it’s not. The <mark data-aos="highlight-text" data-aos-delay="250">users use your product despite the UI</mark> you created. If they could do something less and get the same (or better) result — they would.
        </p>
        <p>
          Don’t start building something by thinking of how it’s going to look — start by thinking <mark data-aos="highlight-text" data-aos-delay="250">what problem it’s going to solve</mark>. Always try to first think of a way to solve the problem without any interaction or additional input from the user at all.
        </p>
        <h3 className="h2">Minimise the numbers of details</h3>
        <p>
          When talking about great experiences it’s important to understand how valuable a simple interface is to the users. Think about the best examples which scaled very well — Google Search for example — up to this day it’s basically nothing more than a simple input field. In the end that’s all you need — one input field or one button.
        </p>
        <blockquote className="blockquote">
          A “<a href="http://make-everything-ok.com/" rel="noopener noreferrer" target="_blank" title="Make Everything OK">Make Everything OK</a>” button.
        </blockquote>
        <p>
          Eliminate the process altogether — <mark data-aos="highlight-text" data-aos-delay="250">make it a huge red button saying ‘I want PIZZA’</mark> which gets their favourite pizza to their current location, debits their card and does it in the next 20mins. Think about that, before thinking about what fonts you should use or selecting the shadow colours for the hundreds of input elements in some forms.
        </p>

        <Tags tags={['UX', 'Product Development', 'Product Management', 'Product Design', 'UI']} />
      </article>
      <Breadcrumb title={Article.title} />
    </React.Fragment>
  );
}