import React, { } from "react";

import Tags from "../../articles/Tags";
import Breadcrumb from "../../articles/Breadcrumb";

import profileSSImg from "../../../assets/img/sarunas-profile.jpeg";
import postImg from "../../../assets/img/posts/over-800-min.jpg";
import thumbImg from "../../../assets/img/posts/over-800-min.jpg";

export const Article = {
  alias: 'over',
  tag: 'culture',
  colour: 'danger',
  blogImg: postImg,
  thumbImg: thumbImg,
  profileImg: profileSSImg,
  author: 'Sarunas Savicianskas',
  posted: 'Sep 26th, 2017',
  title: 'The art of over-engineering',
  intro: 'This point is most likely the most discussed topic between engineers and the managers behind every product — the biggest friction point — what is a healthy level of tech debt which is acceptable for any product?',
  post: <Post />
};

export default function Post(props) {  
  return (
    <React.Fragment>
      <article className="article">
        <p>
          This point is most likely the most discussed topic between engineers and the managers behind every product — the <mark data-aos="highlight-text" data-aos-delay="250">biggest friction</mark> point — what is a <mark data-aos="highlight-text" data-aos-delay="250">healthy level of tech debt</mark> which is acceptable for any product? Management will always push for more speed with as much technical debt as possible for the sake of shipping a lot of new and valuable features which hopefully lead to a lot of value. Engineers will always push back with arguments about scale (<a href="/blog/dysfunctional"  title="Dysfunctional programmers">sometimes too soon or unnecessarily</a>), efficiency and the <mark data-aos="highlight-text" data-aos-delay="250">price which some day they will <a rel="noopener noreferrer" href="https://www.forbes.com/sites/falonfatemi/2016/05/30/technical-debt-the-silent-company-killer/#7fe22d3a4562" target="_blank" title="Tech debt">eventually have to pay</a></mark>. That’s a good argument to have, which must be revisited again and again.
        </p>
        <p>
          I see this topic pretty simple — it depends on the stage of the product at which the needle between ‘A Hack’ and ‘The Perfect System’ goes to one side or the other.
        </p>
        <p>
          If it’s an early stage for the whole product or some feature you want to add and test out — make it as simple as possible with most likely as much sane tech debt as possible. It’s a lesson at this stage and the <mark data-aos="highlight-text" data-aos-delay="250">lesson has to be as cheap as possible</mark>.
        </p>
        <p>
          If you can think of an amazing product idea and build a crazy prototype in a couple of days, then launch it, get some data on the usage, and then see that it’s an absolute failure — that’s awesome! You spent a couple of days to learn that something does not work. You didn’t spend 12 months in your moms garage building the ‘Ultimate CRM’ or ‘Facebook, but better’ to find out that all of that time was a complete waste of time and nobody actually understands it or needs it.
        </p>
        <p>
          If the same data shows you that your idea is great — people are using it — with all the crashes, bugs and limitations — that’s awesome also! You spent a couple of days to learn that something does work. It didn’t take you the same 12 months to prove your idea. Now you have something which you have proven to be valuable and you can focus on thinking how much do you need to iterate for the users to keep using it with the growing scale. It does not yet mean that you need to close the doors and again spend the 12 months building the efficient version now. It means that you need to iterate — to <mark data-aos="highlight-text" data-aos-delay="250">continue <a href="/blog/results" title="Results Only Work Environment">learning with as little price in time</a> and resources as possible</mark>.
        </p>
        <p>
          The same applies for iterations in next stages of the product growth . You always have to <mark data-aos="highlight-text" data-aos-delay="250">aim for the <a href="/blog/results" title="Results Only Work Environment">cheapest possible lessons</a></mark> — cheapest possible failures when iterating new ideas.
        </p>
        <h3 className="h2">Cheap lessons don’t mean providing a poor servicey</h3>
        <p>
          The first thing after you build your first product and first features is to handle the problematic scenarios. Do not expose your users to ambiguity — block them off.
        </p>
        <p>
          Limiting access is always better than providing a poor service. Limited service builds trust. Poor service breaks the trust.
        </p>
        <p>
          The best example here is a simple query in the bank for the user balance. Let’s say your system returns two values — €1,000,000 and €1,000. What do you do in that case? Do you show the first? The second? The smaller one? The average? Or do you just show a ‘Fail Whale’?
        </p>
        <p>
          Another example of a well managed service is a managed load. You can either allow your product to slow down for all of the users or provide a great service for some users, and show a <a href="https://cloud.google.com/blog/products/gcp/using-load-shedding-to-survive-a-success-disaster-cre-life-lessons" rel="noopener noreferrer" target="_blank" title="Queue Whale">‘Queue Whale’</a> for everyone else.
        </p>

        <Tags tags={['Startup', 'Product Development', 'Product Management', 'Product', 'Entrepreneurship']} />
      </article>
      <Breadcrumb title={Article.title} />
    </React.Fragment>
  );
}