import React, { } from "react";

import Tags from "../../articles/Tags";
import Breadcrumb from "../../articles/Breadcrumb";

import profileSSImg from "../../../assets/img/sarunas-profile.jpeg";
import postImg from "../../../assets/img/posts/bounce-800-min.jpg";
import thumbImg from "../../../assets/img/posts/bounce-300-min.jpg";

export const Article = {
  alias: 'bounce',
  tag: 'management',
  colour: 'primary',
  blogImg: postImg,
  thumbImg: thumbImg,
  profileImg: profileSSImg,
  author: 'Sarunas Savicianskas',
  posted: 'Jan 23rd, 2017',
  title: 'Want to get ahead? Bounce the ball!',
  intro: 'I constantly had situations where people’s expectations for me were met, but I felt like I could have performed better and quicker with less. I challenged myself to perform better, but with not much in the way of results.',
  post: <Post />
};

export default function Post(props) {  
  return (
    <React.Fragment>
      <article className="article">
        <p>
          I used to have no specific way of working. I performed good, didn’t really get complaints about my performance. I constantly had situations where people’s expectations for me were met, but I felt like I could have performed better and quicker with less. I challenged myself to perform better, but with not much in the way of results.
        </p>
        <p>
          Then I had a valuable experience of working with one person, who was very quick, both with decisions and actions. It wasn’t always perfect, sometimes even sloppy. But it did get results — especially in situations when it wasn’t just a task depending on a single person. And it taught me one vital lesson — to be exceptionally valuable to any company, I needed to be able to learn from that kind of performance.
        </p>
        <p>
          I once briefly asked why he worked like that — why does everything have to be either bounced back ASAP or rejected as a waste of time? He explained that he has to bounce the ball back as soon as he receives it, otherwise <mark data-aos="highlight-text" data-aos-delay="250">he’s dragging “the game”</mark>. He also has to make sure that once there is an status report check, there are <mark data-aos="highlight-text" data-aos-delay="250">no tasks which are “stuck” on him</mark>.
        </p>
        <p>
          That was the mentality which I was lacking. After refining it a couple of months I came up with a brief guide which I try to follow and encourage others to follow too.
        </p>
        <h3 className="h2">Don’t hog the ball — bounce it!</h3>
        <p>
          We all get tasks, questions, requests all the time — some of them planned, some not. Treat all of them as a ball thrown at you. Most of the people will take their time passing the ball back — with all of the tasks, questions and requests.
        </p>
        <p>
          The problem is that most of the requests will be very minor. Minor requests can be bounced back instantly. They have to be bounced instantly. The value in that is that you add value instantly and can continue to focus on the requests and tasks, which require more of your time and effort.
        </p>
        <p>
          Don’t be afraid to bounce back the requests which you can’t help with at the moment or at all — bounce back with a clear rejection “not now” — don’t just ignore them. This also includes the requests where you can’t actually help — bounce them back with “I don’t know” — be brave to not know everything. If any of the requests are more important — they will bounce back to you.
        </p>
        <p>
          People will appreciate getting value, so that they can create value. Sometimes the value is in getting a “not now” or a “I don’t know” bounce back, because then you can proceed without waiting and expecting something.
        </p>
        <h3 className="h2">Don’t just bounce everything quickly</h3>
        <p>
          When you’re bouncing the minor or useless requests, don’t get into a habit of doing it for everything. Also, don’t be sloppy and give a bounce back which would instantly bounce back to you again. Invest the time to make sure you’re bouncing the ball back the right way. But make the investment of holding on to the ball lean — invest the minimum amount of time and effort to add value. And then bounce the ball.
        </p>
        <p>
          This takes time to become a habit, but eventually you’ll see yourself being able to do the quality work where quality work needs to be done. Everything else you’ll eventually learn to categorise into a couple of categories and bounce back. Once you start bouncing the ball with quality, you will see that it takes you less and less time to actually make a decision, get the information or reject minor requests.
        </p>
        <p>
          You’ll also get into a habit of seeing quality over quantity — adding value over being busy.
        </p>
        <h3 className="h2">Once it’s bounced — put some stress on it</h3>
        <p>
          That’s the whole point of bouncing the ball — to get a result. Once you bounced the ball — put some stress on the person who got the result from you. If you added value, ask to see that value become a result. Same for when you ask someone to do something — feel the stress of bouncing it forward or creating a result.
        </p>
        <p>
          Remember to keep it a healthy amount of stress, don’t become someone whose requests are number one priority over everything else. Allow your Team to invest in a minimum required quality bounce back to you. You don’t want to keep getting the ball just to bounce it back again.
        </p>
        <p>
          This works perfectly when it comes to following up — you will appreciate bouncing the ball at your own pace over getting any additional requests. When you bounce the ball back, you control the situation. When someone pushes you to deliver the result, they control the situation.
        </p>
        <h3 className="h2">Think about your Team</h3>
        <p>
          See your Team as a team in a competitive sport. In competitive sport all players are valuable — everyone adds value. There is no team sport, where a single player can do everything — at least not enough to win enough times in the long run.
        </p>
        <p>
          Don’t just do what you need to do and be done with that. Only in exceptional cases does it actually bring value or make you exceptional. Your team members will either outperform you, and eventually you’re going to start dragging your team down, or you will all fail, because the individual mentality will follow.
        </p>
        <h3 className="h2">What will it get you?</h3>
        <p>
          For me, it was very valuable — I started actually focusing on my time and my Team — I actually got more free time. I stopped treating everything as equal or just a task in my TO DO list. I started bouncing the irrelevant, bouncing the minor and focusing on the things which added most value. I started focusing on quality and value. I stopped focusing on just doing tasks.
        </p>
        <p>
          Once you and your Team will get into a habit of doing quick quality bounces, you will see that you achieve quality results quicker — you will get the edge you need to deliver above the expectations.
        </p>
        <p>
          So the next time you get a request your way, I challenge you — <mark data-aos="highlight-text" data-aos-delay="250">bounce the ball!</mark>
        </p>

        <Tags tags={['Life Lessons', 'Startup', 'Product Development', 'Productivity', 'Motivation']} />
      </article>
      <Breadcrumb title={Article.title} />
    </React.Fragment>
  );
}